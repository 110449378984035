body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



#inspirationDisplay {
  margin: 0 20% 2% 20%;

}

.ignoreUnderline {
  text-decoration: none;
}

.rounded {
border-radius: 50%;
}

.resize-img {
  width: 20px;
  margin: 1em;
}

.space {
  margin-right: 1em;
}
.space-alt {
  margin-left: 1em;
}

body > div.makeStyles-modal-89 > div.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  width: 86%;
  height: 94%;
}

body > div.makeStyles-modal-89 > div.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded > div {
  width:100%;
  height:100%;
  padding: 2em;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}


.justify-center {
  justify-content: center;
}



#h\.ocvpswguxa6m {
  margin-top: -1.9em;
}

.player-wrapper {
  position: relative;
  padding-top: -56.25%; /* 720 / 1280 = 0.5625 */
  display: flex;
  justify-content: space-around;

}

.react-player {
  /* position: absolute; */
  top: 0;
  left: 0;
  max-height:85vw;
  border: 4px solid rgba(202, 202, 202,0.3);
}
.space-fab {
  padding-top: 1em;
  min-height: 100px;
}

.padding-top {
  padding-top: 2em;
}
.padding-bottom {
  padding-bottom: 2em;
}
.padding-left {
  padding-left: 2em;
}
.padding-right {
  padding-right: 2em;
}
.margin-top {
  padding: 2em;
  
}
.margin-bottom {
  margin-bottom: 2em;
}

.margin-bottom-additional {
  margin-bottom: 3em;
}
.margin-bottom-reduce {
  padding: -3em;
}
.margin-left {
  margin-left: 2em;
}

.margin {
  margin-top: 5rem;
}
.red {
  border: solid 2px red;
}

#root {
  position: relative;
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  /* background-color: #eceff1; */
  width: 100vw;
  overflow-x: hidden;
  /* background-color: linear-gradient(to bottom, rgba(207,66,100,0.35) 0%, rgba(25,10,180,.35) 50%, rgba(255,237,188,.35) 100%); */
  /* background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,1.0) 90%); */
  /* filter: blur(8em); */
}


/* MUI css */
.h-full {
  height: 100% !important;
}
.h-full-screen {
  height: 100vh;
}
.hidden {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block !important;
 }
    
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}
.items-stretch {
  align-items: stretch;
}
.flex-grow {
  flex-grow: 1;
}
.overflow-auto {
 overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
} 
.position-relative {
  position: relative;
}
.position-bottom {
  position: absolute;
  bottom: 0;
}
.text-center {
 text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.x-center {
  left: 50%;
  transform: translateX(-50%);
}
.y-center {
  top: 50%;
  transform: translateY(-50%);
}

.vw-resize {

  width: 50vw;
}

.w-full {
  width: 100%;
}
.w-full-screen {
  width: 100vw;
}


.profile-pic {
  height: 200px;
  border-radius: 4px;
}

.scale-down-width {
  /* width: 22vw; */
  width: 22rem;
  /* height: 25vw; */
}

.phrase-wrapper {
  width: 100%;
  text-align: center;
}

.fade {
  display: inline;
  overflow-x: visible;
}
.words{
  display: inline;
  overflow-x: visible;
  text-align: center;
}

.fade span {
  animation: fader 25.0s linear infinite 0s;
  opacity: 0;
	overflow: hidden;
  position: absolute;
  overflow-x: visible;
}

.fade span:nth-child(2){
  animation-delay: 2.5s;

}
.fade span:nth-child(3){
  animation-delay: 5.0s;

}
.fade span:nth-child(4){
  animation-delay: 7.5s;

}
.fade span:nth-child(5){
  animation-delay: 10.0s;

}
.fade span:nth-child(6){
  animation-delay: 12.5s;

}
.fade span:nth-child(7){
  animation-delay: 15.0s;

}
.fade span:nth-child(8){
  animation-delay: 17.5s;

}
.fade span:nth-child(9){
  animation-delay: 20.0s;

}
.fade span:nth-child(10){
  animation-delay: 22.5s;

}

@keyframes fader {
	0% { opacity: 0; }
	10% { opacity: 0; -moz-transform: translateY(0px); }
  15% { opacity: 1; -moz-transform: translateY(0px); }
  20% { opacity: 0; -moz-transform: translateY(0px); }
}

.filtered {
  filter: grayscale(100%);
}

.resize {
  border-radius: 4px;
  height: 224px;
}

.big {
font-size: 2rem;
font-weight: 500;
font-variant: inherit;
}




.phrase-wrapper2 {
  width: 100%;
  text-align: center;
}


.words2 {
  display: inline;
  overflow-x: visible;
  text-align: center;
}

.fade2 {
  animation: 
  fader2 
  1.0s 
  ease-in
  normal
  running;
  color: white;
}



@keyframes fader2 {
	0% { opacity: 0; }
  15% { opacity: 0; -moz-transform: translateY(0px); }
  21% { opacity: 0; -moz-transform: translateY(0px); }
  20% { opacity: 0; -moz-transform: translateY(0px); }
	100% { opacity: 0; }
}
.fade3 {
  animation: 
  fader3
  3.5s;
  color: white;
}



@keyframes fader3 {
	0% { opacity: 0; }
	/* 10% { opacity: 0; -moz-transform: translateY(0px); } */
  15% { opacity: 0; -moz-transform: translateY(0px); }
  21% { opacity: 0; -moz-transform: translateY(0px); }
  20% { opacity: 0; -moz-transform: translateY(0px); }
	100% { opacity: 0; }
}

.border {
  border-left: 4px solid #212121;
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: 1em;
  margin-left: 1em;
  margin-right: 1em;
}
.border-welcome {
  /* border-bottom: 1px solid rgba(255, 255, 255,0.5); */
  border-bottom: 1px solid rgba(0, 0, 0,0.5);
  padding-bottom: 0.33rem;
}



video {
  width: 100vw;
}


.padding {
  padding: .5rem;
}


.blur{
  position: relative;
  background: inherit;
  border-radius: 0px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.95);
}

.blur:after{
 content: '';
 background: inherit; 
 position: absolute;
 z-index: -1;
 left: 0px;
 /* left position */
 right: 0;
 top: 0px;  
 /* top position  */
 bottom: 0;
  /* box-shadow: inset 0 0 0  rgba(255,255,255,0.05); */
 background-color: rgba(255, 255, 255, 0.95);
 /*Why is this grayscale only?*/
 filter: blur(2rem);
}
